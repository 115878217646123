$color_1: #FFF;
$font-family_1: 'Mulish', sans-serif;
$font-family_2: 'Inter';
$background-color_1: inherit;
$background-color_2: transparent;
$background-color_3: #F5F5F5;
$background-color_4: #E2EFFF;
$color_2: rgb(0, 79, 147);
$bg-dark-blue: #004F93;

@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&family=Mulish:wght@400;500;600;700;800&display=swap";

app-dashboard-header .sticky-top {
  z-index: 1000;
}

app-dashboard-header .sticky-top section .navbar-expand-lg {
  // box-shadow: 0px 15px 10px -15px #111;
  border-radius: 2px solid white;
  background: #FFF;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25);
}

.card {
  margin-top: 25px;
}

.mat-mdc-dialog-title {
  padding: 15px 19px !important;
  line-height: 0 !important;

  .custom-modal-title {
    width: 100%;
    text-align: center;
    padding-top: 0;
  }
}

.mat-mdc-dialog-title::before {
  content: none !important;
}

.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #1f549f !important;
}

.mat-mdc-dialog-content {
  // max-height: 78vh !important;
  padding: 0px 10px 24px 10px !important;
  max-width: 120% !important;
  overflow: hidden;

  .dialog-footer {
    margin-top: 15px;
  }
}

.navbar-nav {
  .nav-item {
    a {
      color: #070707;
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 1000;
      font-style: inherit;
      font-variant: initial cur
    }
  }
}

mat-dialog-content.mat-typography {
  .custom-scroll {
    overflow: hidden !important;

    &:hover {
      overflow-y: scroll !important;
    }

    &:hover::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
      border-radius: 15px;
      background-color: $background-color_3;
      transition: background-color .8s;
    }

    &:hover::-webkit-scrollbar {
      width: 12px;
      background-color: $background-color_3;
      transition: background-color .8s;
    }

    &:hover::-webkit-scrollbar-thumb {
      border-radius: 15px;
      -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
      background-color: $background-color_4;
      transition: background-color .8s;
    }


  }

  h2 {
    font-family: $font-family_1;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
    letter-spacing: 1.08px;
    // text-transform: uppercase;
    color: $color_2;
  }

  textarea {
    resize: none;
  }


}

// Modal dailog bottom buttons
.mat-mdc-dialog-actions {
  .dialog-footer {
    .dialog-footer-btn {
      .btn {
        display: inline-flex;
        padding: 8px 96px 8px 97px;
        justify-content: center;
        align-items: center;
        border-radius: 20px;


        span {
          color: $color_1;
          text-align: center;
          font-family: $font-family_2;
          font-size: 16px;
          font-style: normal;
          font-weight: 900;
          line-height: 24px;
          letter-spacing: 0.48px;

        }
      }

      .btn.custom-btn-blue {
        background: #004F93;
        color: white;
        display: inline-flex;
        width: 40px;
        border-radius: 12px;
        width: 122px;
      }

      .btn.custom-btn-green {
        background: #0AC246;
        display: inline-flex;
      }
    }
  }
}

.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.custom-mat-form-field {
  box-shadow: 0px 0px 4px 0px #00000040;
  border-radius: 15px;
  border: 1px solid #D2D5DA
}

.custom-mat-form-field {
  .mat-mdc-form-field-wrapper {
    height: 30px;
    border-radius: 8px;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    padding-bottom: 14px !important;
  }

  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        input.mat-mdc-input-element {
          height: 100%;
          // border: 1px solid #ccc;
          box-sizing: border-box;
          // border-radius: 6px;

        }
      }

      height: 100%;
    }

    height: 100%;
  }

  height: 44px;
}

.mdc-fab--mini {
  width: 30px !important;
  height: 30px !important;
  position: fixed;
  right: 10px;
  top: 0;
  z-index: 100;
}

.custom-mat-form-field .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 9px !important;
}

.mat-mdc-header-row {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: $background-color_1;
}

.custom-paginator-sticky {
  bottom: 0px;
  position: sticky;
  z-index: 10;
}

.mat-mdc-text-field-wrapper {
  .mat-mdc-form-field-flex {
    .mat-mdc-floating-label {
      top: 23px;
    }
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  .mdc-notched-outline--upgraded {
    .mdc-floating-label--float-above {
      --mat-mdc-form-field-label-transform: translateY(-30.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
      transform: var(--mat-mdc-form-field-label-transform);
      background-color: white;
      padding-bottom: 0 !important;
    }
  }

  .mat-mdc-form-field-infix {
    padding-top: 10px;
  }
}

.mdc-text-field--outlined {
  .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border-top-left-radius: var(--mdc-shape-small, 50px) !important;
      border-bottom-left-radius: var(--mdc-shape-small, 50px) !important;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: var(--mdc-shape-small, 15px) !important;
      border-bottom-right-radius: var(--mdc-shape-small, 15px) !important;
    }
  }
}


//Table SCSS for all components
.table-overflow {
  margin-top: 10px;

}

.custom-mat-table td {
  border-bottom: 1px solid #7D7987;
}

table.custom-mat-table {
  background-color: $background-color_2 !important;
  width: 100%;
  border-collapse: collapse;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);



  // dialogbox
  .mdc-data-table__header-row {
    height: 45px;
    // height: 61.5px;
  }
}

.custom-modal-search {
  width: 45% !important;
  border-radius: 8cqhP !important;


}

.custom-paginator {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.20);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom: 1px solid #D2D5DA;
}

table.custom-mat-table {
  thead {
    tr {
      th {
        background: rgb(245, 248, 255) !important;
        text-align: center;
        vertical-align: middle !important;
        color: black;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-family: $font-family_1;
        font-size: 16px;
        // font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 15px;
        // line-height: 10px;
        /* 163.636% */
        letter-spacing: 0.44px;
        //text-transform: uppercase;
        word-wrap: break-word !important;
        white-space: unset !important;
        overflow-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

        &:first-child {
          border-top-left-radius: 15px;
        }

        &:last-child {
          border-top-right-radius: 15px;
        }

        .mat-sort-header-container {
          .mat-sort-header-arrow {
            opacity: 0.54 !important;
            transform: translateY(0) !important;
          }
        }

        .mat-sort-header-container.mat-sort-header-sorted {
          .mat-sort-header-arrow {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  border-spacing: 0;

  tbody {
    tr {
      td {
        text-align: center !important;
        vertical-align: middle !important;
        color: #004F93;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        // font-family: Inter;
        font-size: 14px;
        // font-size: 12.5px;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
        /* 130% */
        letter-spacing: 0.48px;
        word-wrap: break-word !important;
        white-space: unset !important;
        overflow-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        background: #ffff !important;

      }
    }
  }
}

.mat-sort-header-container {
  justify-content: center !important;
}

.table-overflow {
  .custom-mat-table {
    tbody {
      display: block;
      height: 335px;
      overflow: auto;
      border-radius: 15px;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
        border-radius: 15px;
        background-color: $background-color_3;
      }

      &::-webkit-scrollbar {
        width: 12px;
        background-color: $background-color_3;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 15px;
        -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
        background-color: $background-color_4;
      }
    }

    thead {
      display: table;
      width: 100%;
      table-layout: fixed;
      width: calc(100% - 0em);
    }
  }
}

.full-screen-dialog {
  min-height: 50px;
  min-width: 64vw;
  margin-top: 20px;
}

.bulk-screen-dialog {
  min-height: 30px;
  min-width: 39vw;
  margin-top: 20px;
}

.bulk-screen-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: var(--mdc-dialog-container-shape, 4px);
    border-radius: 15px;
  }
}

.full-screen-dialog-Payment {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: var(--mdc-dialog-container-shape, 4px);
    border-radius: 15px;
  }
}

//button active css
.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: $color_1;
  background-color: $bg-dark-blue;
  // border-color: var(--bs-btn-active-border-color);
}

@supports (top: max(0%)) {
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    width: max(12px, var(--mdc-shape-small, 10px)) !important;
  }
}

// .modal-backdrop {
//   --bs-backdrop-zindex: 1050;
//   --bs-backdrop-bg: #000;
//   --bs-backdrop-opacity: 0.5;
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: rgba(var(--bs-emphasis-color-rgb), 0.08);
// }

.cust-em {
  background-color: rgb(221, 215, 215);
  border-radius: 12px;
}

.List_heading {
  color: #1F549F;
}

.custom-modal-filter {
  max-width: 50vw !important;
}

ngx-material-timepicker-container .timepicker-overlay {
  left: 12% !important;
  z-index: 1056 !important;
}


table.custom-mat-table1 {
  thead {
    tr {
      th {
        background: #F5F8FF !important;
        text-align: center !important;
        vertical-align: middle !important;
        color: #1f549f;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-family: $font-family_1;
        font-size: 12px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px;
        /* 163.636% */
        letter-spacing: 0.44px;
        //text-transform: uppercase;
        word-wrap: break-word !important;
        white-space: unset !important;
        overflow-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;



        // &:first-child {
        //   border-top-left-radius: 15px;
        // }

        // &:last-child {
        //   border-top-right-radius: 15px;
        // }

        .mat-sort-header-container {
          .mat-sort-header-arrow {
            opacity: 0.54 !important;
            transform: translateY(0) !important;
          }
        }

        .mat-sort-header-container.mat-sort-header-sorted {
          .mat-sort-header-arrow {
            opacity: 1 !important;
          }
        }
      }
    }
  }

  border-spacing: 0;

  tbody {
    tr {
      td {
        text-align: center !important;
        vertical-align: middle !important;
        // color: BL;
        font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
        font-family: Inter;
        font-size: 10px;
        padding: 0;
        font-style: normal;
        font-weight: 600;
        line-height: 15.6px;
        /* 130% */
        letter-spacing: 0.48px;
        word-wrap: break-word !important;
        white-space: unset !important;
        overflow-wrap: break-word;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
      }
    }
  }
}


// table.custom-mat-table1 {
//   thead {
//     tr {
//       th {
//         background: #F5F8FF !important;
//         text-align: center !important;
//         vertical-align: middle !important;
//         color: #1f549f;
//         font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
//         font-family: $font-family_1;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 800;
//         line-height: 18px;
//         /* 163.636% */
//         letter-spacing: 0.44px;
//         //text-transform: uppercase;
//         word-wrap: break-word !important;
//         white-space: unset !important;
//         overflow-wrap: break-word;
//         word-break: break-word;
//         -ms-hyphens: auto;
//         -moz-hyphens: auto;
//         -webkit-hyphens: auto;
//         hyphens: auto;



//         // &:first-child {
//         //   border-top-left-radius: 15px;
//         // }

//         // &:last-child {
//         //   border-top-right-radius: 15px;
//         // }

//         .mat-sort-header-container {
//           .mat-sort-header-arrow {
//             opacity: 0.54 !important;
//             transform: translateY(0) !important;
//           }
//         }

//         .mat-sort-header-container.mat-sort-header-sorted {
//           .mat-sort-header-arrow {
//             opacity: 1 !important;
//           }
//         }
//       }
//     }
//   }

//   border-spacing: 0;

//   tbody {
//     tr {
//       td {
//         text-align: center !important;
//         vertical-align: middle !important;
//         // color: BL;
//         font-feature-settings: 'cv11' on, 'cv01' on, 'ss01' on;
//         font-family: Inter;
//         font-size: 10px;
//         padding: 0;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 15.6px;
//         /* 130% */
//         letter-spacing: 0.48px;
//         word-wrap: break-word !important;
//         white-space: unset !important;
//         overflow-wrap: break-word;
//         word-break: break-word;
//         -ms-hyphens: auto;
//         -moz-hyphens: auto;
//         -webkit-hyphens: auto;
//         hyphens: auto;
//       }
//     }
//   }
// }


// .table-overflow1 {
//   .custom-mat-table1 {
//     tbody {
//       display: block;
//       height: 60vh;
//       overflow: auto;
//       margin-right: 500px;
//       width: 100%;

//       tr {
//         display: table;
//         width: 100%;
//         table-layout: fixed;
//       }

//       // &::-webkit-scrollbar-track {
//       //   -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
//       //   border-radius: 15px;
//       //   background-color: $background-color_3;
//       // }

//       // &::-webkit-scrollbar {
//       //   width: 12px;
//       //   background-color: $background-color_3;
//       // }

//       // &::-webkit-scrollbar-thumb {
//       //   border-radius: 15px;
//       //   -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
//       //   background-color: $background-color_4;
//       // }
//     }

//     thead {
//       display: table;
//       width: 100%;
//       table-layout: fixed;
//       width: calc(100% - 0em);
//     }
//   }
// }


// .modal-header{.mat-mdc-dialog-title {
//   padding: 20px 10px !important;
//   line-height: 0 !important;
//   background-color: #E3F2F9;
//   height: 66px;
//   .custom-modal-title {
//     width: 100%;
//   }
// }
// }

// .modal-header{.mat-mdc-dialog-actions {
//   .dialog-footer {
//     .dialog-footer-btn {
//       .btn {
//         display: inline-flex;
//         padding: 8px 96px 8px 97px;
//         justify-content: center;
//         align-items: center;
//         border-radius: 22px;
//         box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);

//         span {
//           color: $color_1;
//           text-align: center;
//           font-family: $font-family_2;
//           font-size: 16px;
//           font-style: normal;
//           font-weight: 900;
//           line-height: 24px;
//           letter-spacing: 0.48px;
//         }
//       }

//       .btn.custom-btn-blue {
//         background: #1F549F;
//       }

//       .btn.custom-btn-green {
//         background: #0AC246;
//         display: inline-flex;
//         padding: 8px 96px 8px 97px;
//       }
//     }
//   }
// }}


.custom-modal-filter {
  max-width: 50vw !important;
}

.custom-modal-accept {
  width: 25% !important;
}

.custom-modal-getfeed {
  height: 43rem !important;
}

.custom-modal-suggest {
  width: 30% !important;
}

.custom-modal-details {
  width: 50% !important;
  overflow-y: auto;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  border: none;
}


.addbutton {
  color: var(--white-100, #FFF);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.60px;
}

// .scrol{
//   overflow: auto;
//   height: 62.3vh;
// }
.offset-1 {
  border-radius: 15px;
  width: 812px;
  height: 46px;
  flex-shrink: 0;
}

.chgb {
  display: flex;
  width: 115px;
  padding: 10px var(--8, 9px) 12.08px var(--8, 8px);
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #004F93;
  height: 36px;
}


.mat-typography::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(190, 200, 215, 0.3);
  border-radius: 15px;
  background-color: #F5F5F5;
  ;
}

.mat-typography::-webkit-scrollbar {
  width: 5px !important;
  height: 12px !important;
  background-color: #F5F5F5;
  ;
}

.mat-typography::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px rgba(210, 213, 218, 0.3);
  background-color: #a3c9f7;

  ;
}

// .mat-mdc-tab-body-content {
//   height: 100%;
//   overflow-y: hidden;
// }


.mdc-dialog__content {
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  // overflow: auto;
}

@media screen and (min-width:768px) and (max-width: 1023px) {


  .error-message {
    font-size: 11px !important;
  }

  #left_menu {
    width: 138% !important;
  }

  .List_heading {
    font-size: 18px !important;
  }

  .Search_width {
    width: 78% !important;
  }

  table.custom-mat-table {
    thead {
      tr {
        th {

          font-size: 10.5px;

        }
      }
    }

    tbody {
      tr {
        td {

          font-size: 10px;
        }
      }
    }
  }
}

@media screen and (min-width:1024px) and (max-width: 1439px) {

  table.custom-mat-table {
    thead {
      tr {
        th {

          font-size: 13px;

        }
      }
    }

    tbody {
      tr {
        td {

          font-size: 11.5px;
        }
      }
    }
  }

}


.mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}




.tooltipClass {
  max-width: 50vw !important;
  background-color: white;
}

.custom-tooltip {
  opacity: 1 !important;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #ddd;
}

.tooltip-inner {
  border: 1px solid #ddd;
  background-color: #fff;
  color: #1f549f;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 600;
}

@media (min-width: 992px) and (max-width: 1490.98px) {
  .navbar-nav .nav-item a {
    font-size: 15px;
  }
}

@media (max-width:992px) {
  .custom-modal-accept {
    width: 40% !important;
  }
}

@media (max-width:576px) {
  .custom-modal-accept {
    width: 55% !important;
  }
}